<template>
  <v-select
    class="invoice-status"
    chips
    :disabled="!canUpdate(value)"
    :hide-details="true"
    :hide-selected="true"
    :items="items"
    :value="value"
    @change="onChange"
  >
    <template v-slot:selection>
      <v-chip :color="statusConfig[value]?.color" small class="font-weight-bold" label>
        {{ statusConfig[value]?.text || value }}
        <v-avatar right v-if="canUpdate(value)">
          <v-icon>mdi-chevron-down</v-icon>
        </v-avatar>
      </v-chip>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'ChipsDropdown',
  props: {
    value: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    statusConfig: {
      type: Object,
      required: true,
    },
    canUpdate: {
      type: Function,
      required: true,
    },
  },
  methods: {
    onChange(newStatus) {
      this.$emit('input', newStatus);
    },
  },
};
</script>

<style scoped>
.invoice-status {
  padding-top: 0 !important;
  margin-top: 0 !important;

  .v-chip {
    cursor: pointer;
  }

  .v-input__control > .v-input__slot:before,
  .v-input__control > .v-input__slot:after,
  .v-input__append-inner,
  input {
    display: none !important;
  }
}
</style>
